import fireDeck from '../../assets/photos/fire-deck-01.jpg';
import './Lake.scss';

export default function Lake() {
  return (
    <section className="container--Lake">
      <div className="image-box">
        <img src={fireDeck} alt="deck view" />
      </div>

      <div className="text-box">
        <h3 className="title">Lake Access</h3>
        <p className="description">
          We are located right off of the water, just a few feet from the
          property. Menominee Lake is privately-accessed, making it an excellent
          spot for fishing or quiet and peaceful paddling trips throughout the
          day.
        </p>
      </div>
    </section>
  );
}
