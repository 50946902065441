import { Link } from 'react-router-dom';
import diningArea from '../../assets/photos/dining-area.jpg';
import './Availability.scss';

export default function Availability() {
  return (
    <section>
      <div className="text-box">
        <h3 className="title">Availability</h3>
        <p className="description">
          We rent our property all year round, with a three night minimum stay.
          If interested in pricing please reach out via our contact page.
        </p>
        <Link to="contact">
          <span className="booking-link">Book Now</span>
        </Link>
      </div>
      <div className="image-box">
        <img src={diningArea} alt="dining area" />
      </div>
    </section>
  );
}
