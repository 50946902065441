import { useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import Layout from './components/layout';
import Home from './components/views/Home';
import Gallery from './components/views/Gallery';
import Contact from './components/views/Contact';
import './style.scss';

export default function App() {
  // Disable the ability to open the right click context menu.
  document.addEventListener('contextmenu', (event) => event.preventDefault());

  const [page, setPage] = useState('');
  return (
    <Routes>
      <Route path="/" element={<Layout page={page} />}>
        <Route index element={<Home setPage={setPage} />} />
        <Route path="gallery" element={<Gallery setPage={setPage} />} />
        <Route path="contact" element={<Contact setPage={setPage} />} />
      </Route>
    </Routes>
  );
}
