import banner02 from '../../assets/photos/banner_02_26Oct2022_2000px.jpg';
import './Hero.scss';

export default function Hero() {
  return (
    <div className="banner-image">
      <img src={banner02} alt="front view" />
    </div>
  );
}
