import banner02 from './assets/photos/banner_02_26Oct2022_2000px.jpg';
import bedroom1 from './assets/photos/bedroom-1_7Feb2023.jpg';
import bedroom2 from './assets/photos/bedroom-2.jpg';
import bedroom3 from './assets/photos/bedroom-3_7Feb2023.jpg';
import bedroom4 from './assets/photos/bedroom-4.jpg';
import deckView from './assets/photos/deck-view.jpg';
import diningArea from './assets/photos/dining-area.jpg';
import sofaBedDouble from './assets/photos/double-sofa-bed_7Feb2023.jpg';
import gamesRoom from './assets/photos/games-room.jpg';
import lakeView from './assets/photos/lake-view_7Feb2023.jpg';
import laundryRoom from './assets/photos/laundry-room.jpg';
import loungingArea from './assets/photos/lounging-area_7Feb2023.jpg';
import masterBathroom from './assets/photos/master-bathroom.jpg';
import sharedBathroom1 from './assets/photos/shared-bathroom-1.jpg';
import sharedBathroom2 from './assets/photos/shared-bathroom-2.jpg';
import sunRoom1 from './assets/photos/sun-room-1_7Feb2023.jpg';
import welcome from './assets/photos/welcome_7Feb2023.jpg';

const imageData = {
  1: {
    id: 1,
    title: 'Welcome!',
    description: '',
    source: welcome,
  },
  2: {
    id: 2,
    title: 'Bedroom #1',
    description: 'King bedroom with an ensuite bathroom',
    source: bedroom1,
  },
  3: {
    id: 3,
    title: 'Bedroom #2',
    description: '3rd floor queen bedroom',
    source: bedroom2,
  },
  4: {
    id: 4,
    title: 'Bedroom #3',
    description: '2nd floor queen bedroom',
    source: bedroom4,
  },
  5: {
    id: 5,
    title: 'Bedroom #4',
    description: 'Bunk bedroom',
    source: bedroom3,
  },
  6: {
    id: 6,
    title: 'Sofa bed',
    description: 'Double-sized sofa bed from the king bedroom',
    source: sofaBedDouble,
  },
  7: {
    id: 7,
    title: 'Ensuite bathroom',
    description: 'Bathtub from the ensuite bathroom',
    source: masterBathroom,
  },
  8: {
    id: 8,
    title: 'Deck view',
    description: '',
    source: deckView,
  },
  9: {
    id: 9,
    title: 'Dining area',
    description: '',
    source: diningArea,
  },
  10: {
    id: 10,
    title: 'Front view',
    description: '',
    source: banner02,
  },
  11: {
    id: 11,
    title: 'Games room',
    description: '',
    source: gamesRoom,
  },
  12: {
    id: 12,
    title: 'Lake view',
    description: '',
    source: lakeView,
  },
  13: {
    id: 13,
    title: 'Laundry area',
    description: '',
    source: laundryRoom,
  },
  14: {
    id: 14,
    title: 'Lounging area',
    description: '',
    source: loungingArea,
  },
  15: {
    id: 15,
    title: 'Shared bathroom',
    description: '',
    source: sharedBathroom1,
  },
  16: {
    id: 16,
    title: 'Shared bathroom',
    description: '',
    source: sharedBathroom2,
  },
  17: {
    id: 17,
    title: 'Sun room',
    description: '',
    source: sunRoom1,
  },
};

export default imageData;
