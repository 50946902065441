import { useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { brands } from '@fortawesome/fontawesome-svg-core/import.macro';
import MenuButton from './MenuButton';
import './Navigation.scss';

export default function Navigation({ page }) {
  const [openMenu, setOpenMenu] = useState(false);
  const toggleOpenMenuHandler = () => {
    setOpenMenu((prev) => !prev);
  };
  let menuClass;
  if (openMenu) {
    menuClass = 'menu active';
    // Disable page scrolling.
    document.body.style.overflow = 'hidden';
  } else {
    menuClass = 'menu inactive';
    // Enable page scrolling.
    document.body.style.overflow = 'auto';
  }

  return (
    <nav>
      <MenuButton openMenu={openMenu} toggleOpenMenu={toggleOpenMenuHandler} />
      <ul className={menuClass}>
        <div className="menu--nav">
          <Link to="/">
            <li
              className={page === 'HOME' ? 'selected' : ''}
              onClick={() => setOpenMenu(false)}
            >
              Home
            </li>
          </Link>
          <Link to="gallery">
            <li
              className={page === 'GALLERY' ? 'selected' : ''}
              onClick={() => setOpenMenu(false)}
            >
              Gallery
            </li>
          </Link>
          <Link to="contact">
            <li
              className={page === 'CONTACT' ? 'selected' : ''}
              onClick={() => setOpenMenu(false)}
            >
              Contact
            </li>
          </Link>
        </div>
        <div className="menu--social">
          <span className="icon" onClick={() => setOpenMenu(false)}>
            <a
              href="https://www.instagram.com/joie_muskoka/"
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon icon={brands('instagram')} />
            </a>
          </span>
        </div>
      </ul>
      <div className="shader" onClick={() => setOpenMenu(false)} />
    </nav>
  );
}
