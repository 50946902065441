import { useEffect, useState } from 'react';
import GalleryList from '../sections/GalleryList';
import ImageModal from '../sections/GalleryList/ImageModal';
import './Gallery.scss';

export default function Gallery({ setPage }) {
  useEffect(() => {
    window.scrollTo(0, 0);
    setPage('GALLERY');
  });

  const [modalImage, setModalImage] = useState(null);

  // Disable scrolling & user select when an image is opened in the modal.
  if (modalImage) {
    document.body.style.overflow = 'hidden';
    document.body.style.userSelect = 'none';
  } else {
    document.body.style.overflow = 'visible';
    document.body.style.userSelect = 'auto';
  }

  return (
    <main className="container Gallery">
      <h3 className="page-title">Gallery</h3>
      <GalleryList setModalImage={setModalImage} />
      {modalImage && (
        <ImageModal modalImage={modalImage} setModalImage={setModalImage} />
      )}
    </main>
  );
}
