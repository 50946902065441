import laundyRoom from '../../assets/photos/laundry-room.jpg';
import lakeIcon from '../../assets/icons/lake.png';
import canoeIcon from '../../assets/icons/canoe.png';
import kayakIcon from '../../assets/icons/kayak.png';
import firepitIcon from '../../assets/icons/firepit.png';
import grillIcon from '../../assets/icons/grill.png';
import sunIcon from '../../assets/icons/sun.png';
import wifiIcon from '../../assets/icons/wifi.png';
import cookingToolsIcon from '../../assets/icons/cooking-tools.png';
import dishwasherIcon from '../../assets/icons/dishwasher.png';
import tvIcon from '../../assets/icons/tv.png';
import arcadeIcon from '../../assets/icons/arcade.png';
import laundryIcon from '../../assets/icons/laundry.png';
import './Amenities.scss';

export default function Amenities() {
  return (
    <div className="container--Amenities">
      <div className="image-box">
        <img src={laundyRoom} alt="laundy room" />
      </div>

      <div className="amenities-box">
        <h3 className="title">Amenities</h3>
        <div className="amenities">
          <span className="icons">
            <img src={lakeIcon} alt="lake icon" height="50px" />
            Lake Access
          </span>
          <span className="icons">
            <img src={canoeIcon} alt="canoe icon" height="50px" />
            Single Canoe
          </span>
          <span className="icons">
            <img src={kayakIcon} alt="kayak icon" height="50px" />
            Four Kayaks
          </span>
          <span className="icons">
            <img src={firepitIcon} alt="firepit icon" height="50px" />
            Stone Firepit
          </span>
          <span className="icons">
            <img src={grillIcon} alt="grill icon" height="50px" />
            Weber Grill
          </span>
          <span className="icons">
            <img src={sunIcon} alt="sun icon" height="50px" />
            Sun Room
          </span>
          <span className="icons">
            <img src={wifiIcon} alt="wifi icon" height="50px" />
            WiFi
          </span>
          <span className="icons">
            <img
              src={cookingToolsIcon}
              alt="cooking tools icon"
              height="50px"
            />
            Cooking Essentials
          </span>
          <span className="icons">
            <img src={dishwasherIcon} alt="dishwasher icon" height="50px" />
            Dishwasher
          </span>
          <span className="icons">
            <img src={tvIcon} alt="tv icon" height="50px" />
            Two Smart TVs
          </span>
          <span className="icons">
            <img src={arcadeIcon} alt="arcade icon" height="50px" />
            Game Room
          </span>
          <span className="icons">
            <img src={laundryIcon} alt="laundry machine icon" height="50px" />
            Washer &amp; Dryer
          </span>
        </div>
        <p className="footnote">
          *Please note that life jackets are not provided
        </p>
      </div>
    </div>
  );
}
