import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { brands } from '@fortawesome/fontawesome-svg-core/import.macro';
import './Footer.scss';

export default function Footer() {
  return (
    <div className="container--footer">
      <div className="social-media">
        <span className="icon">
          <a
            href="https://www.instagram.com/joie_muskoka/"
            target="_blank"
            rel="noreferrer"
          >
            <FontAwesomeIcon icon={brands('instagram')} />
          </a>
        </span>
      </div>

      <p className="copyright">
        &copy; 2022 Joie Muskoka. Designed by{' '}
        <a href="https://johnngai.com/" target="_blank" rel="noreferrer">
          John Ngai
        </a>
        .
      </p>
    </div>
  );
}
