import { useEffect } from 'react';
import Hero from '../sections/Hero';
import Story from '../sections/Story';
import Amenities from '../sections/Amenities';
import Accomodations from '../sections/Accomodations';
import Lake from '../sections/Lake';
import Availability from '../sections/Availability';
import './Home.scss';

export default function Home(props) {
  const { setPage } = props;
  useEffect(() => {
    window.scrollTo(0, 0);
    setPage('HOME');
  });

  return (
    <main className="container--Home">
      <Hero />
      <Story />
      <Amenities />
      <Accomodations />
      <Lake />
      <Availability />
    </main>
  );
}
