import masterBathroom from '../../assets/photos/master-bathroom.jpg';
import kingBedIcon from '../../assets/icons/king-bed.png';
import bathtubIcon from '../../assets/icons/bathtub.png';
import queenBedIcon from '../../assets/icons/queen-bed.png';
import bunkBedIcon from '../../assets/icons/bunk-bed.png';
import doubleSofaBed from '../../assets/icons/double-sofa-bed.png';
import queenSofaBedIcon from '../../assets/icons/queen-sofa-bed.png';
import './Accomodations.scss';

export default function Accomodations() {
  return (
    <div className="container--Beds">
      <div className="text-box">
        <h3 className="title">Accomodations</h3>
        <p className="description">
          Comfortably sleeps 10-12 guests with 5 bedroom accommodations. The
          property has 1 king bedroom with an ensuite + double sofa bed, 2 queen
          bedrooms, a bunk bedroom, and a queen sofa bed in the entertainment
          room.
        </p>

        <div className="bedrooms">
          <span className="icons">
            <div>
              <img src={kingBedIcon} alt="king bed icon" height="50px" />
              <img
                src={doubleSofaBed}
                alt="double sofa bed icon"
                height="50px"
              />
              <img src={bathtubIcon} alt="bathtub icon" height="50px" />
            </div>
            1 King &amp; Ensuite + Double Sofa Bed
          </span>
          <span className="icons">
            <div>
              <img src={queenBedIcon} alt="queen bed icon" height="50px" />
              <img src={queenBedIcon} alt="queen bed icon" height="50px" />
            </div>
            2 Queen Beds
          </span>
          <span className="icons">
            <img src={bunkBedIcon} alt="bunk bed icon" height="50px" />1 Bunk
            Bed
          </span>
          <span className="icons">
            <img
              src={queenSofaBedIcon}
              alt="queen sofa bed icon"
              height="50px"
            />
            1 Queen Sofa Bed
          </span>
        </div>
      </div>

      <div className="image-box">
        <img src={masterBathroom} alt="master bathroom" />
      </div>
    </div>
  );
}
