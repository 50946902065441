import { useEffect } from 'react';
import ContactForm from '../sections/ContactForm';
import './Contact.scss';

export default function Contact(props) {
  const { setPage } = props;
  useEffect(() => {
    window.scrollTo(0, 0);
    setPage('CONTACT');
  });

  return (
    <main className="container--Contact">
      <ContactForm />
    </main>
  );
}
