import { useRef } from 'react';
import emailjs from '@emailjs/browser';
import muskokaChairs from '../../assets/photos/muskoka-chairs_2000px_26Oct2022.jpg'
import './ContactForm.scss';

export default function ContactForm() {
  const form = useRef();
  const sendEmail = (event) => {
    event.preventDefault();
    emailjs
      .sendForm(
        'service_h5otbmr',
        'template_gqblaj7',
        form.current,
        'nmuJG79S986QoqHnJ'
      )
      .then(
        () => {
          alert('Message successfully sent!');
          window.location.reload(false);
        },
        () => {
          alert('Failed to send the message, please try again');
        }
      );
  };

  return (
    <section className="container ContactForm">
      <div className="contact-image-box">
        <img src={muskokaChairs} alt="deck view" />
      </div>
      <h3 className="title">Contact Us</h3>
      <p className="description">
        If you're interested in booking our property, there is a 3-night minimum
        stay. Please use the form below to inquire about our availability,
        recommended local activities, or any additional questions you may have.
      </p>
      <form ref={form} onSubmit={sendEmail}>
        <ul>
          <li className="half">
            <input type="text" name="name" placeholder="Name" required />
          </li>
          <li className="half">
            <input type="email" name="email" placeholder="Email" required />
          </li>
          <li>
            <input type="text" name="subject" placeholder="Subject" required />
          </li>
          <li>
            <textarea name="message" placeholder="Message" required />
          </li>
          <li>
            <input className="send-button" type="submit" value="SEND" />
          </li>
        </ul>
      </form>
    </section>
  );
}
