import sunRoom from '../../assets/photos/sun-room-2.jpg';
import './Story.scss';

export default function Story() {
  return (
    <div className="container--Story">
      <div className="text-box">
        <h3 className="title">Our Story</h3>
        <p className="description">
          Joie Muskoka is a Hawaiian-inspired retreat located in the heart of
          Muskoka. Guests of Joie Muskoka will have a grand lakefront view with
          a large back yard and deck directly overlooking Menominee Lake. Wake
          up and soak up the gorgeous panoramic views, then take advantage of
          our many kayaks for a paddling adventure. When you're back, sit back
          and relax in our sun room or come inside to enjoy the many modern
          amenities.
        </p>
      </div>
      <div className="image-box">
        <img src={sunRoom} alt="sun room" />
      </div>
    </div>
  );
}
